import { WagmiAdapter } from '@reown/appkit-adapter-wagmi';
import {
  mainnet,
  optimism,
  base,
  arbitrum,
  sepolia,
} from '@reown/appkit/networks';
import { cookieStorage, createStorage } from '@wagmi/core';
import { supportedNetworkIds } from '~/lib/helpers/flags';

// Get projectId from https://cloud.reown.com
export const projectId = 'fc3bbc377b9cafafaa16e07f5d07e4ce';

if (!projectId) {
  throw new Error('Project ID is not defined');
}

const wagmiChains = [optimism, base, arbitrum, sepolia];

const networkIds: number[] = JSON.parse(
  supportedNetworkIds || '[1, 10, 8453, 42161, 11155111]'
);
const networkIdsSet = new Set(networkIds);

export const filteredChains = wagmiChains.filter((wChain) =>
  networkIdsSet.has(wChain.id)
);
export const chains = [mainnet, ...filteredChains];

export type ExcitingChainIds = `${(typeof chains)[number]['id']}`;
export type ChainIds = ExcitingChainIds | '0';

export const isSupportedNetwork = (chainId: number) =>
  chains.some((chain) => chain.id === chainId);

//Set up the Wagmi Adapter (Config)
export const wagmiAdapter = new WagmiAdapter({
  storage: createStorage({
    storage: cookieStorage,
  }),
  ssr: true,
  projectId,
  networks: [mainnet, ...filteredChains],
});
