'use client';

import { useSession, useVault, useWallet } from '@kiroboio/fct-sdk';
import { isBuilderDisabled, isMarketplaceOff } from '~/lib/helpers/flags';
import { useUser } from '~/lib/hooks/useUser/useUser';
import { ZERO_ADDRESS } from '../builder/Provider/constants';
import { isSupportedNetwork } from '~/lib/wagmi/config';
import { useAccount } from '~/lib/hooks/useAccount';
import { useNetwork } from '~/lib/hooks/useNetwork';

interface ICustomGuardProps {
  children: React.ReactNode;
  unsupportedNetworkJsx?: React.ReactNode;
  serviceStateMessageJsx?: React.ReactNode;
  connectWalletMessageJsx?: React.ReactNode;
  signMessageJsx?: React.ReactNode;
  signingMessageJsx?: React.ReactNode;
  systemLoadingJsx?: React.ReactNode;
  notWhitelistedJsx?: React.ReactNode;
  missingVaultJsx?: React.ReactNode;
  notOwnerJsx?: React.ReactNode;
  notBuilderJsx?: React.ReactNode;
}

export const GuardWithMessage = ({
  children,
  unsupportedNetworkJsx,
  serviceStateMessageJsx,
  connectWalletMessageJsx,
  // signMessageJsx,
  signingMessageJsx,
  systemLoadingJsx,
  notWhitelistedJsx,
  missingVaultJsx,
  notOwnerJsx,
  notBuilderJsx,
}: ICustomGuardProps) => {
  const {
    // status,
    connectionState,
    // isServiceReady,
    account: { type: accountType },
  } = useSession();
  const { isConnected } = useAccount();
  const { isSigningIn, isSignedOut, isSigningIdle } = useUser();
  const wallet = useWallet();
  const vault = useVault();
  const { chainId } = useNetwork();

  const isUnsupportedNetwork = !isSupportedNetwork(Number(chainId));

  const isServiceDisconnected = connectionState === 'disconnected';
  const isBuilder = accountType !== 'user';
  const isServiceReady = connectionState === 'authorized';

  const { isRegistered: isWhitelisted } = wallet?.data?.raw || {
    isRegistered: false,
  };
  const {
    hasVault,
    isOwner,
    address: vaultAddress,
  } = vault?.data?.raw || {
    hasVault: false,
    isOwner: false,
    address: '',
  };

  const hasRealVault =
    hasVault &&
    vaultAddress.toLowerCase() !== '' &&
    vaultAddress.toLowerCase() !== ZERO_ADDRESS.toLowerCase();

  if (unsupportedNetworkJsx && isUnsupportedNetwork) {
    return unsupportedNetworkJsx;
  }
  if (serviceStateMessageJsx && isServiceDisconnected) {
    // console.error({
    //   time: new Date().getTime(),
    //   connectionState,
    //   isServiceDisconnected,
    // });
    return serviceStateMessageJsx;
  }
  if (systemLoadingJsx && !isServiceReady) {
    // console.error({
    //   time: new Date().getTime(),
    //   connectionState,
    //   isServiceReady,
    // });
    return systemLoadingJsx;
  }
  if (notBuilderJsx && !isBuilder && !isBuilderDisabled && isMarketplaceOff) {
    return notBuilderJsx;
  }
  if (
    connectWalletMessageJsx &&
    (!isConnected || isSigningIdle || isSignedOut)
  ) {
    return connectWalletMessageJsx;
  }

  if (signingMessageJsx && isSigningIn) {
    return signingMessageJsx;
  }
  if (notWhitelistedJsx && !isWhitelisted) {
    return notWhitelistedJsx;
  }
  if (missingVaultJsx && !hasRealVault) {
    return missingVaultJsx;
  }
  if (notOwnerJsx && hasRealVault && !isOwner) {
    return notOwnerJsx;
  }
  return children;
};
