import { service } from '@kiroboio/fct-sdk';
import moment from 'moment';

export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const formatGWei = (value: string) =>
  service.formatting.prebuild.formatValue({
    service: 'gas',
    name: 'gas-price',
    decimals: 9,
    digits: 4,
    value,
  });

export const calculateExpGasAdjustment = (
  seconds = 60,
  skew = 0.5,
  blockTime = 12
) => {
  const blocks = Math.floor(seconds / blockTime);
  const MAX_CHANGE_PER_BLOCK = 0.125 / Math.pow(blocks, skew);
  return Math.pow(1 + MAX_CHANGE_PER_BLOCK, blocks);
};

export const secondsToDate = (seconds: string | number) =>
  moment.unix(Number(seconds)).format(DATE_TIME_FORMAT);
