'use client';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
} from '@chakra-ui/react';
import { useAppKit } from '@reown/appkit/react';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect } from 'react';
import { useConnectHandler } from '~/lib/hooks/general/useConnectHandler';
import {
  becomeABuilderId,
  missingOnChainWalletId,
  notWhitelistedId,
  useBecomeABuilderModalActions,
  useMissingOnChainWalletModalActions,
  useNotWhitelistedModalActions,
} from '~/lib/hooks/useModals/useComplexModals';
import { useUser } from '~/lib/hooks/useUser/useUser';

interface IAlertMessage {
  title: string;
  description: string;
  status: 'error' | 'warning' | 'success' | 'info';
}

interface IAlertMessageWithBtn extends IAlertMessage {
  btnText: string;
  btnOnClick: () => void;
  isRunning: boolean;
}

export const AlertMessage = ({ title, description, status }: IAlertMessage) => {
  return (
    <Alert status={status}>
      <AlertIcon />
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{description}</AlertDescription>
    </Alert>
  );
};

export const AlertMessageWithBtn = ({
  title,
  description,
  status,
  btnText,
  btnOnClick,
  isRunning,
}: IAlertMessageWithBtn) => {
  return (
    <Alert status={status}>
      <AlertIcon />
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>
        {description}{' '}
        <Button
          onClick={btnOnClick}
          isLoading={isRunning}
          colorScheme="blue"
          color="blue.500"
          variant="link"
          fontSize="16px"
        >
          {btnText}
        </Button>
      </AlertDescription>
    </Alert>
  );
};

// export const UnsupportedNetworkAlertMessage = () => {
//   return (
//     <AlertMessage
//       status="error"
//       title="Unsupported Network"
//       description="Please switch to a supported network."
//     />
//   );
// };

export const UnsupportedNetworkAlertMessage = () => {
  const t = useTranslations();
  const { open } = useAppKit();
  // const { isConnected } = useAccount();

  const { isSigningIn } = useUser();

  const handleChangeNetwork = useCallback(() => {
    open({ view: 'Networks' });
  }, [open]);

  // useEffect(() => {
  //   if (!isConnected) handleChangeNetwork();
  // }, [handleChangeNetwork, isConnected]);

  return (
    <AlertMessageWithBtn
      status="error"
      title={t('unsupported-network')}
      description={t('please-switch-to-a-supported-network')}
      btnText={t('change-network')}
      btnOnClick={handleChangeNetwork}
      isRunning={isSigningIn}
    />
  );
};

export const ServiceDownAlertMessage = () => {
  const t = useTranslations();
  return (
    <AlertMessage
      status="error"
      title={t('service-is-offline')}
      description={t(
        'please-check-your-internet-connection-or-try-again-later'
      )}
    />
  );
};

// export const ConnectWalletAlertMessage = () => {
//   return (
//     <AlertMessage
//       status="info"
//       title="Connect Wallet."
//       description="You have to connect your wallet to begin."
//     />
//   );
// };
export const ConnectWalletAlertMessage = () => {
  const t = useTranslations();
  const { connectHandler } = useConnectHandler();

  const { isSigningIn } = useUser();

  // const handleConnect = useCallback(() => {
  //   open({ view: 'Connect' });
  // }, [open]);

  // useEffect(() => {
  //   if (!isConnected) handleConnect();
  // }, [handleConnect, isConnected]);

  // console.log('isConnected', isConnected);

  return (
    <AlertMessageWithBtn
      status="info"
      title={t('connect-wallet-0')}
      description={t('you-have-to-connect-your-wallet-to-begin')}
      btnText={t('connect-wallet')}
      btnOnClick={connectHandler}
      isRunning={isSigningIn}
    />
  );
};

export const LoginAlertMessage = () => {
  const t = useTranslations();
  return (
    <AlertMessage
      status="info"
      title={t('login-0')}
      description={t('please-login-to-continue-0')}
    />
  );
};

export const LoggingInAlertMessage = () => {
  const t = useTranslations();
  return <AlertMessage status="info" title={t('logging-in')} description="" />;
};

export const SystemLoadingAlertMessage = () => {
  const t = useTranslations();
  return (
    <AlertMessage
      status="info"
      title={t('system-is-loading-0')}
      description=""
    />
  );
};

export const NotWhitelistedAlertMessage = () => {
  const t = useTranslations();
  const { open: openNotWhitelisted } =
    useNotWhitelistedModalActions(notWhitelistedId);

  const handleOpenNotWhitelisted = useCallback(() => {
    openNotWhitelisted({});
  }, [openNotWhitelisted]);

  return (
    <AlertMessageWithBtn
      status="error"
      title={t('limited-edition')}
      description={t('you-dont-have-access')}
      btnText={t('join-whitelist')}
      btnOnClick={handleOpenNotWhitelisted}
      isRunning={false}
    />
  );
};

export const MissingVaultAlertMessage = () => {
  const t = useTranslations();
  const { open: openMissingOnChainWallet } =
    useMissingOnChainWalletModalActions(missingOnChainWalletId);

  const handleOpenMissingOnChainWallet = useCallback(() => {
    openMissingOnChainWallet({});
  }, [openMissingOnChainWallet]);

  return (
    <AlertMessageWithBtn
      status="error"
      title={t('runner-not-found')}
      description=""
      btnText={t('create-one')}
      btnOnClick={handleOpenMissingOnChainWallet}
      isRunning={false}
    />
  );
};

export const BackupVaultAlertMessage = () => {
  const t = useTranslations();
  return (
    <AlertMessage
      status="error"
      title={t('backup-runner-is-connected')}
      description={t('switch-main-wallet-in-order-to-use-this-app')}
    />
  );
};

// export const NotBuilderAlertMessage = () => {
//   const t = useTranslations();
//   return (
//     <AlertMessage
//       status="error"
//       title={t('builders-only')}
//       description={t('this-page-is-only-for-builders')}
//     />
//   );
// };

export const NotBuilderAlertMessage = () => {
  const t = useTranslations();

  const { open: onOpenBecomeABuilder } =
    useBecomeABuilderModalActions(becomeABuilderId);

  const handleOpenBecomeABuilder = useCallback(() => {
    onOpenBecomeABuilder({});
  }, [onOpenBecomeABuilder]);

  useEffect(() => {
    handleOpenBecomeABuilder();
  }, [handleOpenBecomeABuilder]);

  return (
    <AlertMessage
      status="error"
      title={t('builders-only')}
      description={t('this-page-is-only-for-builders')}
    />
  );
};
