/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import type { StackProps } from '@chakra-ui/react';
import { HStack, Icon, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { Chart21, Setting3, TickCircle, Timer } from 'iconsax-react';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useEffect, useRef, useState } from 'react';
import { FeatherTurtle } from '~/assets/FeatherTurtle';
import { useGetPaymentPerPayer } from '../../Provider/hooks/useGetPaymentPerPayer.ts/useGetPaymentPerPayer';
import { useMSCallOptions } from '../../Provider/hooks/useMSCallOptions';

import { useTranslations } from 'next-intl';
import { useDebounce } from 'react-use';
import { formatUsdService } from '~/lib/utils/number';
import { GasOption } from './GasOption';
import GweiInput from './GweiInput';

export const GasDetailsAdvanced: FC<
  StackProps & {
    isMagicExist: boolean;
    isDisabled?: boolean;
    gasOption: string;
    converted: ReturnType<typeof useGetPaymentPerPayer>;
    setGasOption: Dispatch<SetStateAction<string>>;
    immediateOrSoonGwei: string;
    flexibleTimingGwei: string;
    immediateOrSoonWei: string;
    flexibleTimingWei: string;
  }
> = ({
  isMagicExist,
  isDisabled,
  gasOption,
  converted: { isLoading, converted },
  setGasOption,
  immediateOrSoonWei,
  flexibleTimingWei,
  immediateOrSoonGwei,
  flexibleTimingGwei,
}) => {
  const [customClicked, setCustomClicked] = useState(false);
  const initialFocusRef = useRef<HTMLInputElement>(null);

  const {
    msCallOptions,
    update,
    form: {
      formState: { errors },
    },
  } = useMSCallOptions();

  const [maxGasPriceDebounce, setMaxGasPriceDebounce] = useState(
    String(msCallOptions.maxGasPrice)
  );

  useEffect(() => {
    if (customClicked) {
      initialFocusRef.current?.focus();
    }
  }, [customClicked]);

  const setValue = (value: string) => {
    if (isMagicExist) {
      return;
    }
    setGasOption(value);
    const map: Record<string, string> = {
      1: flexibleTimingWei,
      2: immediateOrSoonWei,
      // 3: futureProofWei,
    };
    if (value === '4') {
      setCustomClicked(true);
      return;
    }
    value && update({ maxGasPrice: map[value] });
    setCustomClicked(false);
  };

  useEffect(() => {
    if (gasOption) {
      setValue(gasOption);
    }
  }, [gasOption]);

  useDebounce(
    () => {
      if (gasOption !== '4') return;
      update({ maxGasPrice: maxGasPriceDebounce });
    },
    300,
    [maxGasPriceDebounce, gasOption]
  );

  const t = useTranslations();
  if (isMagicExist) {
    return (
      <Stack justify="center" spacing="0" gap="32px">
        <HStack ml="-3px">
          <Icon
            as={TickCircle}
            color="green.500"
            boxSize="22px"
            variant="Bold"
          />
          <Text fontWeight="semibold">{t('execution-is-possible-now')}</Text>
        </HStack>
      </Stack>
    );
  }

  return (
    <Stack justify="center" spacing="0" gap="24px" px="10px">
      <Text ml="15px" fontWeight="semibold">
        {t('select-max-fee')}
      </Text>

      <RadioGroup onChange={setValue} value={gasOption}>
        <Stack>
          <Stack
            spacing="0"
            rounded="16px"
            border="1px"
            borderColor={
              errors.maxGasPrice && !customClicked
                ? 'orange.500'
                : 'transparent'
            }
          >
            <GasOption
              isDisabled={isDisabled}
              isChecked={gasOption === '1'}
              title={t('flexible-timing')}
              value="1"
              icon={<FeatherTurtle />}
              isLoading={isLoading}
              usd={`$${formatUsdService(converted.slow)}`}
              gwei={flexibleTimingGwei}
              description={t('use-this-when-exact-timing-is-not-important')}
            />
            <GasOption
              isDisabled={isDisabled}
              data-cy="pick-current-gas"
              isChecked={gasOption === '2'}
              isLoading={isLoading}
              title={t('immediate-when-possible')}
              value="2"
              icon={
                <Icon
                  as={Chart21}
                  boxSize="6"
                  color="primary.500"
                  variant="Bold"
                />
              }
              usd={`$${formatUsdService(converted.fast)}`}
              gwei={immediateOrSoonGwei}
              description={t(
                'will-run-when-conditions-are-met-and-network-fees-do-not-exceed-immediateorsoongwei-gwei',
                { gwei: immediateOrSoonGwei }
              )}
            />
            <GasOption
              isDisabled={isDisabled}
              isChecked={gasOption === '4'}
              title={t('custom')}
              isLoading={isLoading}
              value="4"
              icon={
                <Icon
                  as={Setting3}
                  boxSize="6"
                  color="gray.500"
                  variant="Bold"
                />
              }
              usd={
                customClicked ? `$${formatUsdService(converted.custom)}` : ''
              }
              gwei={
                customClicked
                  ? (+msCallOptions.maxGasPrice * 1e-9).toFixed(2)
                  : ''
              }
              description={
                <Stack py="2" px="4">
                  <Text fontSize="sm" color="gray.400">
                    {t('set-max-gas-price-max-gwei')}{' '}
                  </Text>
                  <GweiInput
                    ref={initialFocusRef}
                    isDisabled={isDisabled}
                    autoFocus={gasOption === '4'}
                    value={maxGasPriceDebounce}
                    onChange={(weiAsString) => {
                      setMaxGasPriceDebounce(weiAsString);
                    }}
                    textAlign="center"
                    rounded="12px"
                    size="md"
                    w="full"
                  />
                </Stack>
              }
            />
          </Stack>
        </Stack>

        {errors.maxGasPrice && (
          <Text
            textAlign="center"
            pt="4"
            color="orange.500"
            fontSize="14px"
            fontWeight="400"
          >
            {t('please-select-max-fee-gas-price')}
          </Text>
        )}
      </RadioGroup>

      {msCallOptions.maxGasPrice && (
        <Stack pl="15px">
          {converted.customCanRunNow ? (
            <HStack ml="-3px">
              <Icon
                as={TickCircle}
                color="green.500"
                boxSize="22px"
                variant="Bold"
              />
              <Text fontWeight="semibold">
                {t('execution-is-possible-now')}
              </Text>
            </HStack>
          ) : (
            <Stack ml="-3px">
              <HStack>
                <Icon as={Timer} color="orange.500" variant="Bold" />
                <Text fontWeight="semibold">Your Intent will be delayed</Text>
              </HStack>
              <Text fontSize="sm" color="gray.400">
                {t(
                  'after-you-sign-the-execution-of-the-intent-will-be-delayed-until-the-network-fees-are-lower-than-the-max-fee-you-set'
                )}
              </Text>
            </Stack>
          )}
          {converted.customCanRunNow && (
            <Text fontSize="sm">
              {t.rich('payment-upon-immediate-execution', {
                amount: () => (
                  <Text
                    fontSize="md"
                    as="span"
                    fontWeight="semibold"
                    color="green.400"
                  >
                    {`$${formatUsdService(converted.customNow)}`}
                  </Text>
                ),
              })}
            </Text>
          )}
        </Stack>
      )}
    </Stack>
  );
};
