// /* eslint-disable react/no-unescaped-entities */
import { HStack, Text } from '@chakra-ui/react';
import { useNetwork } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';

import { NetworkNameBadge } from '~/lib/components/NetworkNameBadge';

interface INetworkInfo {
  gasless?: boolean;
}

export const NetworkInfo: FC<INetworkInfo> = ({ gasless }) => {
  const { data: networkData } = useNetwork();
  const t = useTranslations();
  const {
    // minGasPrice: minGasPriceFmt,
    gasPrice: {
      fastest: { maxFeePerGas: maxFeePerGasFmt },
    },
    // maxGasPrice: maxGasPriceFmt,
  } = networkData.fmt;

  return (
    <HStack spacing="15px" pb="15px">
      <HStack>
        {
          // networkQuery.isLoading ? <Spinner boxSize="6" /> : null // TODO: FOR TAL: ADD isLoading to network hook
        }
        <Text fontSize="sm" fontWeight="medium" color="gray.400">
          {t('network')}{' '}
        </Text>
        <NetworkNameBadge />
      </HStack>
      {!gasless && (
        <Text fontSize="sm">
          <Text as="span" color="gray.400">
            {t('current')}:
          </Text>{' '}
          <Text as="span" fontWeight="semibold">
            {maxFeePerGasFmt} gwei
          </Text>
        </Text>
      )}
    </HStack>
  );
};
