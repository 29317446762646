import { Box, Text, useColorMode } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import type { FC } from 'react';
import { isMarketplaceOff } from '~/lib/helpers/flags';

interface KiroboLogoProps {
  narrowMode?: boolean;
}

export const KiroboLogo: FC<KiroboLogoProps> = ({ narrowMode }) => {
  const { colorMode } = useColorMode();

  const narrowLogo = '/icons/intentable_icon.png';

  const fullLogo =
    colorMode === 'light'
      ? '/logos/intentable_light_logo.png'
      : '/logos/intentable_dark_logo.png';

  return (
    <Link href={isMarketplaceOff ? '/assets/tokens' : '/trading/all_pairs'}>
      <Box
        visibility="visible"
        height="40px"
        width={narrowMode ? '40px' : '137px'}
        transition="all 0.4s ease-out"
        position="relative"
      >
        <Image
          height="40"
          width={narrowMode ? '40' : '137'}
          priority
          alt="logo"
          src={narrowMode ? narrowLogo : fullLogo}
        />
        <Text
          position="absolute"
          right="0"
          bottom="-8px"
          color="blue.300"
          fontSize="12px"
          fontWeight="600"
        >
          BETA
        </Text>
      </Box>
    </Link>
  );
};
