'use client';

import { useSession } from '@kiroboio/fct-sdk';
import {
  useAppKit,
  useAppKitAccount,
  useDisconnect,
} from '@reown/appkit/react';
import { useCallback } from 'react';

import { useUser } from '~/lib/hooks/useUser/useUser';

export const useConnectHandler = () => {
  const { isConnected } = useAppKitAccount();
  const { open } = useAppKit();

  const { login } = useSession();
  const user = useUser();
  const { disconnect } = useDisconnect();

  const connectHandler = useCallback(async () => {
    if (!isConnected) {
      await open({ view: 'Connect' });
    } else if (user.isSignedIn) {
      open({ view: 'Account' });
    } else {
      await login();
      if (user.isSignedIn === false) {
        disconnect();
      }
    }
  }, [isConnected, user.isSignedIn, open, login, disconnect]);

  return {
    connectHandler,
  };
};
