'use client';

import { Stack } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

import type { AppsDefiDataOrderByType, OrderDirType } from '@kiroboio/fct-sdk';
import { useAppsDefiDataList, useWallet } from '@kiroboio/fct-sdk';
import { TradingTableList } from './TradingTableList/TradingTableList';
import type {
  FilterMinMaxParam,
  FilterValuesParam,
} from '@kiroboio/fct-sdk/utils/filtering';
import { RouteTypes } from './routeTypes';
import { useDebounce, usePrevious } from 'react-use';
import { useDeviceSelectors } from 'react-device-detect';
import { usePathname, useSearchParams } from 'next/navigation';
import { isWithSecurityPassedSwitch } from './TradingTableList/config';

interface Query {
  routeType: RouteTypes;
  filterQuery: {
    rank?: FilterMinMaxParam;
    search?: string;
    holders?: string[];
    categories?: FilterValuesParam;
  };
}

const TradingPairsTableQueryWrapper = ({
  search,
}: {
  search: string | null | undefined;
}) => {
  const [orderBy, setOrderBy] = useState<AppsDefiDataOrderByType>('none');
  const [orderDir, setOrderDir] = useState<OrderDirType>('asc');
  const [isSecurityPassed, setIsSecurityPassed] = useState<boolean | undefined>(
    undefined
  );
  const pathname = usePathname();

  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  const wallet = useWallet();
  const holder = wallet?.data?.raw?.address;
  const queryConfig = useCallback(
    (path: `${RouteTypes}`): Query => {
      return {
        '/trading/all_pairs': {
          routeType: RouteTypes.all_pairs,
          filterQuery: { categories: undefined },
        },
        '/trading/top_pairs': {
          routeType: RouteTypes.top_pairs,
          filterQuery: { categories: { or: ['top-pairs'] } },
        },
        '/trading/new_pairs': {
          routeType: RouteTypes.new_pairs,
          filterQuery: { categories: { or: ['new-pairs'] } },
        },
        '/trading/hot_pairs': {
          routeType: RouteTypes.hot_pairs,
          filterQuery: { categories: { or: ['hot-pairs'] } },
        },
        '/trading/my_tokens': {
          routeType: RouteTypes.my_tokens,
          filterQuery: {
            // categories: { or: ['my-tokens'] },
            holders: [holder],
          },
        },
      }[path];
    },
    [holder]
  );

  const [query, setQuery] = useState<Query>(queryConfig('/trading/all_pairs'));
  const prevRouteType = usePrevious(query.routeType);

  const {
    list: apps,
    meta,
    fetchNext,
    downsize,
    isIdle,
    isLoading,
  } = useAppsDefiDataList({
    filter: {
      rank: { min: 80 },
      ...query.filterQuery,
      appType: 'trading',
      search: search || undefined,
      passedSecurity: isWithSecurityPassedSwitch(query.routeType)
        ? isSecurityPassed || undefined
        : undefined,
    },
    order: { by: orderBy, dir: orderDir },
    clearOnChange: prevRouteType !== query.routeType,
  });

  const hasMore = meta.total !== meta.fetched;
  useEffect(() => {
    const route = pathname as RouteTypes;
    if (!Object.values(RouteTypes).includes(route)) return;

    setQuery(queryConfig(route));
  }, [pathname, holder, queryConfig]);

  useEffect(() => {
    return () => {
      downsize(80);
    };
  }, [downsize]);

  const nextHandler = useCallback(() => {
    fetchNext.execute({ limit: 20 });

    return fetchNext;
  }, [fetchNext]);

  // useEffect(() => {
  //   nextHandler();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [query.routeType]);
  //
  // useEffect(() => {
  //   if (isSuccess) {
  //     nextHandler();
  //   }
  // }, [isSuccess]);

  return (
    <Stack gap="4" mb="8" mx={isMobile ? 0 : ['-4px', '-8px', '-16px']}>
      <TradingTableList
        type={query.routeType}
        apps={apps || []}
        isLoading={isIdle || isLoading}
        orderDir={orderDir}
        orderBy={orderBy}
        hasMore={hasMore}
        fetchNext={nextHandler}
        onRouteChange={(route) => {
          setQuery(queryConfig(route));
        }}
        onOrderChange={(nextOrderBy) => {
          setOrderBy((prevOrderBy) => {
            if (prevOrderBy === nextOrderBy) {
              setOrderDir((prevOrderDir) =>
                prevOrderDir === 'asc' ? 'desc' : 'asc'
              );
              return prevOrderBy;
            }
            setOrderDir('desc');
            return nextOrderBy;
          });
        }}
        isSecurityPassed={isSecurityPassed}
        setIsSecurityPassed={setIsSecurityPassed}
      />
    </Stack>
  );
};

export const TradingPairs = () => {
  const searchParams = useSearchParams();
  const search = searchParams.get('search');
  const [debouncedSearch, setDebouncedSearch] = useState<string | null>();

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    300,
    [search]
  );

  return <TradingPairsTableQueryWrapper search={debouncedSearch} />;
};
