'use client';

import { useSession, useVault } from '@kiroboio/fct-sdk';
import { ZERO_ADDRESS } from '~/lib/components/builder/Provider/constants';
import { useAppKitAccount } from '@reown/appkit/react';

export const useUser = () => {
  const { isConnected } = useAppKitAccount();

  const { status, account, dummy } = useSession();
  const {
    data: {
      raw: { address: vaultAddress },
    },
  } = useVault();

  const isSigningIdle = status === 'idle' && !account.isDummy;
  const isSignedIn = status === 'loggedIn' && !account.isDummy;
  const isSigningIn = status === 'loggingIn' || status === 'silentLoggingIn';
  const isSignedOut = status === 'loggedOut';
  const { isDummy } = account;
  const isUsingVault =
    !isDummy && Boolean(vaultAddress) && vaultAddress !== ZERO_ADDRESS;
  const isNotConnected =
    !isConnected || isSigningIdle || isSignedOut || account.isDummy;

  return {
    isSigningIdle,
    isSignedIn,
    isSigningIn,
    isSignedOut,
    isDummy,
    isUsingVault,
    isNotConnected,
    dummy,
  };
};
