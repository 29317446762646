import {
  InputProps as ChakraInputProps,
  Input,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

export interface InputProps extends Omit<ChakraInputProps, 'onChange'> {
  onChange?: (valueAsString: string, valueAsNumber: number) => void;
  maxDecimals?: number;
  ref: React.Ref<HTMLInputElement>;
}

const GweiInput = ({
  value,
  onChange,
  placeholder = 'Enter amount in Gwei',
  maxDecimals = 9, // Default to 9 decimals since 1 Gwei = 10^9 Wei
  ref,
  ...props
}: InputProps) => {
  const [displayValue, setDisplayValue] = useState<string>('');

  // Convert Wei to Gwei (includes decimal handling)
  const weiToGwei = (wei: string): string => {
    if (!wei) return '';
    try {
      // Convert to BigInt and handle division
      const weiBigInt = BigInt(wei);
      const gweiBase = BigInt(10 ** 9);
      const integerPart = weiBigInt / gweiBase;
      const decimalPart = weiBigInt % gweiBase;

      if (decimalPart === BigInt(0)) {
        return integerPart.toString();
      }

      // Format decimal part with proper padding
      let decimalStr = decimalPart.toString().padStart(9, '0');
      // Remove trailing zeros
      decimalStr = decimalStr.replace(/0+$/, '');

      return `${integerPart}.${decimalStr}`;
    } catch {
      return '';
    }
  };

  // Convert Gwei to Wei (includes decimal handling)
  const gweiToWei = (gwei: string): string => {
    if (!gwei) return '';
    try {
      const [integerPart, decimalPart = ''] = gwei.split('.');
      const paddedDecimal = decimalPart.padEnd(9, '0').slice(0, 9);
      const fullNumber = `${integerPart}${paddedDecimal}`;
      return fullNumber.replace(/^0+/, '') || '0'; // Remove leading zeros but keep single zero
    } catch {
      return '';
    }
  };

  // Update display value when external value changes
  useEffect(() => {
    if (value !== undefined) {
      setDisplayValue(weiToGwei(value.toString()));
    }
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    // Allow empty input
    if (!inputValue) {
      setDisplayValue('');
      onChange?.('', 0);
      return;
    }

    // Validate decimal number format
    const decimalRegex = new RegExp(`^\\d*\\.?\\d{0,${maxDecimals}}$`);
    if (!decimalRegex.test(inputValue)) {
      return;
    }

    // Update display value (in Gwei)
    setDisplayValue(inputValue);

    // Convert to Wei and call onChange
    const weiValue = gweiToWei(inputValue);
    onChange?.(weiValue, Number(inputValue));
  };

  return (
    <InputGroup size={props.size}>
      <Input
        ref={ref}
        value={displayValue}
        onChange={handleChange}
        placeholder={placeholder}
        type="text"
        {...props}
      />
      <InputRightAddon>Gwei</InputRightAddon>
    </InputGroup>
  );
};
GweiInput.displayName = 'GweiInput';

export default GweiInput;
