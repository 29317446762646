import type { Edge, FCTNode } from '@kiroboio/fct-builder';
import type { AppsDefiDataItemType } from '@kiroboio/fct-sdk';

export interface PublishModalProps {
  name: string;
  app: string;
  app_version: string;
  builder: string;
  builder_address: string;
  domain: string;
  appId?: string;
  defiDataId?: string;
  mode: number;
  isOptions: boolean;
  options?: AppsDefiDataItemType['raw']['apps'][0]['options'];
  logo?: string;
  draft?: { nodes: FCTNode[]; edges: Edge[] };
}

// export const initPublishModalProperties: PublishModalProps = {
//   name: '',
//   app: '',
//   app_version: '',
//   builder: '',
//   builder_address: '',
//   domain: '',
// };

export const initPublishModalProperties: PublishModalProps = {
  name: '',
  app: 'visual builder',
  app_version: '0.3.0',
  builder: '',
  builder_address: '0x0000000000000000000000000000000000000000',
  domain: 'fct@intentable.io',
  isOptions: false,
  mode: 0,
};
