'use client';

import {
  proxy,
  useSnapshot,
  useVault,
  useWalletActions,
} from '@kiroboio/fct-sdk';
import type { WalletKitTypes } from '@reown/walletkit';
import { WalletKit } from '@reown/walletkit';
import { useMutation } from '@tanstack/react-query';
import { Core } from '@walletconnect/core';
import { getSdkError } from '@walletconnect/utils';
import { useEffect } from 'react';

import { walletConnectProjectId } from '~/lib/wagmi';

import { createNamespacesForWalletConnect } from './utils';

const core = new Core({
  projectId: walletConnectProjectId,
});

const web3wallet = WalletKit.init({
  core,
  metadata: {
    name: 'kirobo-onchain-wallet',
    description: 'Vault Transactions Flow',
    url: 'http://localhost:3000/',
    icons: ['https://vault1213.kirobo.me/icons/favicon-192.svg'],
  },
});

export type WalletConnectSessionsMap = ReturnType<
  Awaited<typeof web3wallet>['getActiveSessions']
>;
export type WalletConnectSession = WalletConnectSessionsMap[0];

const dappSessions = proxy<{
  sessions: WalletConnectSessionsMap;
}>({ sessions: {} });

const walletProxy = proxy<{ web3Wallet: Awaited<typeof web3wallet> | null }>({
  web3Wallet: null,
});

export const getSessionsByAddress = (
  address: string,
  sessions: WalletConnectSessionsMap
) => {
  const addressRegex = new RegExp(address, 'i');

  return Object.values(sessions).filter((s) =>
    Object.values(s.namespaces).some((n) =>
      n.accounts.some((a) => addressRegex.test(a))
    )
  );
};

export const useDappConnectSessions = () => {
  const vaultAddress = useVault().data.raw.address;
  const { sessions } = useSnapshot(dappSessions);
  return {
    sessions: getSessionsByAddress(
      vaultAddress,
      sessions as WalletConnectSessionsMap
    ),
  };
};

const updateSessions = () => {
  const sessions = walletProxy.web3Wallet?.getActiveSessions();
  dappSessions.sessions = sessions || {};
};

export const useDappDisconnectMutation = () => {
  return useMutation({
    mutationFn: async (topic: string) => {
      walletProxy.web3Wallet
        ?.disconnectSession({
          topic,
          reason: getSdkError('USER_DISCONNECTED'),
        })
        .finally(updateSessions);
    },
  });
};

const abortPairingProxy = proxy<{
  abortPairing: (msg?: string) => void;
}>({
  abortPairing: () => {},
});

export const useDappPairMutation = () => {
  const vaultAddress = useVault().data.raw.address;
  return useMutation({
    mutationFn: async (uri: string) => {
      return new Promise((resolve, reject) => {
        abortPairingProxy.abortPairing();

        if (!walletProxy.web3Wallet || !vaultAddress)
          reject(new Error('Wallet not initialized'));
        else {
          walletProxy.web3Wallet
            ?.pair({ uri, activatePairing: true })
            .then(() => {
              // console.log('pairing success');
              const sessionsProposal = (
                proposal: WalletKitTypes.SessionProposal
              ) => {
                const { id, params } = proposal;
                const namespaces = createNamespacesForWalletConnect(
                  vaultAddress as string,
                  params
                );

                walletProxy.web3Wallet
                  ?.approveSession({ id, namespaces })
                  .then(resolve)
                  .catch(reject);
              };

              walletProxy.web3Wallet?.once(
                'session_proposal',
                sessionsProposal
              );

              abortPairingProxy.abortPairing = (msg?: string) => {
                walletProxy.web3Wallet?.off(
                  'session_proposal',
                  sessionsProposal
                );
                reject(new Error(msg || 'User aborted'));
              };

              setTimeout(() => {
                abortPairingProxy.abortPairing('Timeout');
              }, 10000);
            })
            .catch(() => {
              // console.log('pairing error');
              reject();
            });
        }
      });
    },
    onSettled: () => {
      updateSessions();
    },
  });
};

export const useDappConnect = () => {
  const vaultAddress = useVault().data.raw.address;

  const { onchain, onchainVaultParams } = useWalletActions();

  useEffect(() => {
    const sessionRequest = async (
      event: WalletKitTypes.SessionRequest
    ): Promise<void> => {
      const request = event.params.request.params[0];
      if (request.from.toLowerCase() !== vaultAddress?.toLowerCase()) return;

      try {
        // console.log('dapp call', request);
        // console.log(
        //   'onchain params',
        //   onchainVaultParams([
        //     { to: request.to, value: request.value || '0', data: request.data },
        //   ])
        // );
        const tx = await onchain.execute(
          onchainVaultParams([
            {
              to: request.to,
              value: request.value || '0',
              data: request.data,
            },
          ])
        );
        // console.log('tx', tx.error);

        if (tx.error) {
          throw new Error(
            (tx.error as Error)?.message || 'Something went wrong'
          );
        }
        walletProxy.web3Wallet?.respondSessionRequest({
          topic: event.topic,
          response: {
            id: event.id,
            jsonrpc: '2.0',
            result: tx.results?.tx.hash,
          },
        });
      } catch (error) {
        console.error('error:', error);
        walletProxy.web3Wallet?.respondSessionRequest({
          topic: event.topic,
          response: {
            id: event.id,
            jsonrpc: '2.0',
            error: {
              code: 5000,
              message: 'User rejected.',
            },
          },
        });
      }
    };

    const sessionDelete = async (
      session: Omit<WalletKitTypes.BaseEventArgs<unknown>, 'params'>
    ): Promise<void> => {
      walletProxy.web3Wallet
        ?.disconnectSession({
          topic: session.topic,
          reason: getSdkError('USER_DISCONNECTED'),
        })
        .catch((e) => console.error(e))
        .finally(updateSessions);
    };

    const prepare = async () => {
      walletProxy.web3Wallet = await web3wallet;
      const walletConnect = walletProxy.web3Wallet as Awaited<
        typeof web3wallet
      >;

      updateSessions();
      walletConnect.on('session_request', sessionRequest);
      walletConnect.on('session_delete', sessionDelete);
    };
    prepare();

    return () => {
      walletProxy.web3Wallet?.off('session_request', sessionRequest);
      walletProxy.web3Wallet?.off('session_delete', sessionDelete);
    };
  }, [onchain, onchainVaultParams, vaultAddress]);
};
