'use client';

import type React from 'react';
import type { FC } from 'react';
import { memo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

type InfiniteScrollProps = InfiniteScroll['props'];
interface InfiniteScrollEfficientProps extends InfiniteScrollProps {
  dataLength: number;
  hasMore: boolean;
  next: () => void;
  loader: React.ReactNode;
  endMessage?: React.ReactNode;
  children: React.ReactNode;
  height?: string | number;
}

export const InfiniteScrollEfficient: FC<InfiniteScrollEfficientProps> = memo(
  ({
    dataLength,
    hasMore,
    next,
    loader,
    endMessage,
    children,
    height,
    ...props
  }) => {
    return (
      <InfiniteScroll
        scrollableTarget="main"
        dataLength={dataLength}
        hasMore={hasMore}
        next={next}
        loader={loader}
        endMessage={endMessage}
        height={height}
        {...props}
      >
        {children}
      </InfiniteScroll>
    );
  }
);
