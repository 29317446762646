'use client';

import { Stack } from '@chakra-ui/react';
import { redirect } from 'next/navigation';
import {
  ServiceDownAlertMessage,
  SystemLoadingAlertMessage,
  UnsupportedNetworkAlertMessage,
} from '~/lib/components/general/AlertMessages';
import { isMarketplaceOff } from '~/lib/helpers/flags';

import { GuardWithMessage } from '~/lib/components/general/GuardWithMessage';
import { TradingPairs } from '~/lib/components/Trading/TradingPairs';
import Welcome from './welcome';

export default function Trading({ children }: { children: React.ReactNode }) {
  if (isMarketplaceOff) redirect('/');

  return (
    <GuardWithMessage
      unsupportedNetworkJsx={<UnsupportedNetworkAlertMessage />}
      serviceStateMessageJsx={<ServiceDownAlertMessage />}
      systemLoadingJsx={<SystemLoadingAlertMessage />}
    >
      <Stack gap="0">
        <Welcome />
        {children}
        <TradingPairs />
      </Stack>
    </GuardWithMessage>
  );
}
