'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { proxy } from '@kiroboio/fct-sdk';

export const pathHistoryManager = proxy<{
  counter: number;
}>({
  counter: 0,
});

const setCounter = () => {
  pathHistoryManager.counter += 1;
};

export default function PathHistory() {
  const pathname = usePathname();

  useEffect(() => {
    if (pathname) {
      setCounter();
    }
  }, [pathname]);

  return null;
}
