'use client';

/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { yupResolver } from '@hookform/resolvers/yup';
import type { IFCTOptions } from '@kiroboio/fct-core';
import { useNetwork } from '@kiroboio/fct-sdk';
import _, { merge } from 'lodash';
import moment from 'moment';
import type React from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { create } from 'zustand';

// import { useProjectQuery } from './projectQueries/useProjectQuery';

type PartiallyOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type PartialFCTOptions = PartiallyOptional<
  IFCTOptions,
  'recurrency' | 'multisig'
>;

export const SINGLE_DAY = '86400';
export const RECURRENCY_CHILL_TIME_DEFAULT = SINGLE_DAY;
export const RECURRENCY_MAX_REPEATS_DEFAULT = '1';
export const RECURRENCY_ACCUMETABLE_DEFAULT = false;

function getObjectPaths(obj: Record<string, unknown>, prefix = ''): string[] {
  return _.flatMap(_.toPairs(obj), ([key, value]) =>
    typeof value === 'object' && value !== undefined && value !== null
      ? getObjectPaths(value as Record<string, unknown>, `${prefix + key}.`)
      : prefix + key
  );
}

// create a type that will make every prop in object optional also all the nested props
type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : DeepPartial<T[P]>;
};

export const createMSCallOptions = (
  opts: Partial<IFCTOptions> = {
    payableGasLimit: '0',
  }
): PartialFCTOptions =>
  merge(
    { payableGasLimit: '0' },
    {
      name: '',
      dryRun: false,
      app: {
        name: '',
        version: '',
      },
      builder: {
        name: '',
        address: '',
      },
      domain: '',
      verifier: '',
      id: '',
      validFrom: moment().unix().toString(),
      expiresAt: moment().add(10, 'minutes').unix().toString(),
      // maxGasPrice: '100000000000',
      maxGasPrice: '0',
      blockable: true,
      purgeable: false,
      authEnabled: false,
      forceDryRun: false,
      // recurrency: {
      //   accumetable: false,
      //   chillTime: '0',
      //   maxRepeats: '1',
      // },
    },
    opts
  );

export const useMSCallOptionsStore = create<{
  msCallOptions: PartialFCTOptions;
  setMSCallOptions: (
    setter: (prev: PartialFCTOptions) => PartialFCTOptions
  ) => void;
}>((set, get) => ({
  msCallOptions: createMSCallOptions({
    builder: {
      name: '',
      address: '0x0000000000000000000000000000000000000000',
    },
  }),
  setMSCallOptions: (
    setter: (prev: PartialFCTOptions) => PartialFCTOptions
  ) => {
    set((state) => {
      const newState = setter(state.msCallOptions);
      return { msCallOptions: newState };
    });
  },
}));

export const _useMSCallOptions = () => {
  const { data: networkData } = useNetwork();
  // const project = useProjectQuery();
  const externalSigner = networkData.raw.fctSigner;

  const { msCallOptions, setMSCallOptions } = useMSCallOptionsStore();
  // console.log('msCallOptions:', msCallOptions);

  const [isVirtualPauseEnabled, _setIsVirtualPauseEnabled] = useState(false);
  const [isRecurrencyEnabled, _setIsRecurrencyEnabled] = useState(
    !!msCallOptions.recurrency
  );
  const [calcGasAutomatically, setCalcGasAutomatically] = useState(false);

  const form = useForm({
    defaultValues: { ...msCallOptions },
    resolver: yupResolver(
      yup.object().shape({
        // name: yup.string().required(),
        validFrom: yup
          .string()
          .required()
          .test('is valid  date', 'Must be a valid date', (value) => {
            return moment.unix(Number(value)).isValid();
          }),
        expiresAt: yup
          .string()
          .required()
          .test('is valid unix date', 'Must be a valid date', (value) => {
            return moment.unix(Number(value)).isValid();
          })
          .test('is not in the past', 'Must be in the future', (value) => {
            return moment.unix(Number(value)).isAfter(moment());
          })
          .test('is after validFrom', 'Must be after start date', (value) => {
            return moment
              .unix(Number(value))
              .isAfter(moment.unix(Number(msCallOptions.validFrom)));
          }),
        maxGasPrice: yup
          .string()
          .required('gas price is a required field')
          .test('greater than 0', 'Must be greater than 0', (value) => {
            return Number(value) > 0;
          }),
        ...(isRecurrencyEnabled && {
          recurrency: yup.object().shape({
            maxRepeats: yup
              .string()
              .required('Required')
              .test('greater than 0', 'Must be greater than 0', (value) => {
                return Number(value) > 0;
              }),
            chillTime: yup
              .string()
              .required('Required')
              .test(
                'greater than 0',
                'Must be greater than or equal to 0',
                (value) => {
                  return Number(value) >= 0;
                }
              ),
            accumetable: yup.boolean(),
          }),
        }),
      })
    ),
  });

  const virtualPauseResetData = {
    externalSigners: [],
    minimumApprovals: '0',
  };
  const virtualPauseFillData = {
    externalSigners: [externalSigner],
    minimumApprovals: '1',
  };

  const toggleVirtualPause = useCallback(() => {
    return _setIsVirtualPauseEnabled((enabled) => {
      setMSCallOptions((state) => {
        if (enabled) {
          _.merge({}, state, { multisig: { ...virtualPauseResetData } });
          return _.omit(state, 'multisig');
        }
        return state;
      });
      return !enabled;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVirtualPauseEnabled]);

  useEffect(() => {
    if (isVirtualPauseEnabled) {
      setMSCallOptions((state) => {
        return _.merge({}, state, {
          multisig: { ...virtualPauseFillData },
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVirtualPauseEnabled]);

  const recurrencyInitialData = {
    accumetable: RECURRENCY_ACCUMETABLE_DEFAULT,
    chillTime: RECURRENCY_CHILL_TIME_DEFAULT,
    maxRepeats: RECURRENCY_MAX_REPEATS_DEFAULT,
  };

  const toggleRecurrency = useCallback(() => {
    return _setIsRecurrencyEnabled((enabled) => {
      setMSCallOptions((state) => {
        if (enabled) {
          update({ recurrency: { ...recurrencyInitialData } });
          return _.omit(state, 'recurrency');
        }
        form.setValue(
          'recurrency.chillTime',
          RECURRENCY_CHILL_TIME_DEFAULT as never
        );
        form.setValue(
          'recurrency.maxRepeats',
          RECURRENCY_MAX_REPEATS_DEFAULT as never
        );
        return _.merge({}, state, {
          recurrency: { ...recurrencyInitialData },
        });
      });
      return !enabled;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.setValue]);

  // useNetwork({
  //   onSuccess: data => {
  //     if (!calcGasAutomatically) return;
  //     update({ maxGasPrice: data.formatted.recommendedGasBL.toString() });
  //   },
  // });

  const update = useCallback(
    (opts: DeepPartial<IFCTOptions>, shouldValidate = true) => {
      getObjectPaths(opts).forEach((_path) => {
        const path = _path as Parameters<typeof form.setValue>['0'];
        const value = _.get(opts, path);

        form.setValue(path, value as never, { shouldValidate });
      });

      setMSCallOptions((prev) => _.merge({}, prev, opts));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form.setValue]
  );

  // useEffect(() => {
  //   if (!project) return;
  //   update({ name: project.raw.name }, false);
  // }, [project, update]);

  return {
    update,
    msCallOptions,
    form,
    calcGasAutomatically,
    setCalcGasAutomatically,
    isRecurrencyEnabled,
    toggleRecurrency,
    isVirtualPauseEnabled,
    toggleVirtualPause,
  };
};

export type MSCallOptionsContextValue = ReturnType<typeof _useMSCallOptions>;
export const MSCallOptionsContext = createContext<
  MSCallOptionsContextValue | undefined
>(undefined);

export const MSCallOptionsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const msCallOptions = _useMSCallOptions();

  return (
    <MSCallOptionsContext.Provider value={msCallOptions}>
      {children}
    </MSCallOptionsContext.Provider>
  );
};

export const useMSCallOptions = () => {
  const context = useContext(MSCallOptionsContext);
  if (!context) {
    throw new Error(
      'useSharedMSCallOptions must be used within a MSCallOptionsProvider'
    );
  }
  return context;
};
