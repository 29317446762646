import type { StackProps } from '@chakra-ui/react';
import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Tag,
  Text,
} from '@chakra-ui/react';

import moment from 'moment';
import { useTranslations } from 'next-intl';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { useGetPaymentPerPayer } from '../../Provider/hooks/useGetPaymentPerPayer.ts';
import { useMSCallOptions } from '../../Provider/hooks/useMSCallOptions';
import { GasDetailsAdvanced } from './GasDetailsAdvanced';
import { secondsToDate } from './gasUtils';
import { InfoPopover } from './InfoPopover';

export enum TimeUnits {
  Minutes = 60,
  Hours = 60 * 60,
  Days = 60 * 60 * 24,
}

export const HUMAN_TIME_UNITS = {
  [TimeUnits.Minutes]: 'Minutes',
  [TimeUnits.Hours]: 'Hours',
  [TimeUnits.Days]: 'Days',
};

export interface ExpiresAtDisplay {
  label: string;
  value: string;
}

export const expiresAtOptions: { [key: string]: ExpiresAtDisplay } = {
  '3600': { label: '1 hour', value: '3600' },
  '86400': { label: '1 day', value: '86400' },
  '604800': { label: '7 days', value: '604800' },
  '2592000': { label: '1 month', value: '2592000' },
  '31556905200': { label: 'Forever', value: '31556905200' }, // 1000 years
  custom: { label: 'Custom', value: '0' },
};

export const FCTSettingsAdvanced: FC<
  StackProps & {
    name: string;
    isOptions: boolean;
    isMagicExist: boolean;
    gasOption: string;
    setGasOption: Dispatch<SetStateAction<string>>;
    converted: ReturnType<typeof useGetPaymentPerPayer>;
    immediateOrSoonGwei: string;
    flexibleTimingGwei: string;
    immediateOrSoonWei: string;
    flexibleTimingWei: string;
    duration: number;
  }
> = ({
  name,
  isOptions,
  isMagicExist,
  gasOption,
  setGasOption,
  converted,
  flexibleTimingWei,
  immediateOrSoonWei,
  immediateOrSoonGwei,
  flexibleTimingGwei,
  duration,
  ...props
}) => {
  const {
    msCallOptions,
    isRecurrencyEnabled,
    toggleRecurrency,
    isVirtualPauseEnabled,
    toggleVirtualPause,
    update,
    form: {
      formState: { errors },
    },
  } = useMSCallOptions();

  const t = useTranslations();
  const expiresAtDisplay =
    expiresAtOptions[String(duration)] || expiresAtOptions.custom;
  const expiresAtElement = useRef<HTMLInputElement>(null);
  const [chillTimeDisplay, setChillTimeDisplay] = useState<string>('0');
  const [timeUnit, setTimeUnit] = useState(TimeUnits.Days);
  const [maxCountEnabled, setMaxCountEnabled] = useState(false);

  const updatedMaxRepeats = () => {
    if (!maxCountEnabled || !isRecurrencyEnabled) return;

    const maxRepeats = calcMaxRepeats();

    if (!isOptions) {
      update({ recurrency: { maxRepeats } });
    }
  };

  const calcMaxRepeats = () => {
    const { validFrom, expiresAt, recurrency } = msCallOptions;

    const runningPeriod = Number(expiresAt) - Number(validFrom);
    return Math.ceil(runningPeriod / +(recurrency?.chillTime || 1)).toString();
  };

  useEffect(() => {
    updatedMaxRepeats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    maxCountEnabled,
    isRecurrencyEnabled,
    msCallOptions.validFrom,
    msCallOptions.expiresAt,
    msCallOptions.recurrency?.chillTime,
  ]);

  const updateChillTime = useCallback(
    (chillTimeDisplay: string, timeUnit: number) => {
      const chillTime = String(Number(chillTimeDisplay) * timeUnit);
      update({
        recurrency: { chillTime },
      });
    },
    [update]
  );

  const updateTimeUnit = useCallback(
    (unitDisplay: TimeUnits) => {
      if (!chillTimeDisplay) return;
      setTimeUnit(unitDisplay);
      updateChillTime(chillTimeDisplay, unitDisplay);
    },
    [updateChillTime, chillTimeDisplay]
  );

  const updateChillTimeDisplay = useCallback(
    (chillTimeDisplay: string) => {
      setChillTimeDisplay(chillTimeDisplay);
      updateChillTime(chillTimeDisplay, timeUnit);
    },
    [updateChillTime, timeUnit]
  );

  useEffect(() => {
    const currentChillTime = Number(msCallOptions.recurrency?.chillTime || 0);
    if (currentChillTime >= TimeUnits.Days) {
      setTimeUnit(TimeUnits.Days);
      setChillTimeDisplay(
        Math.floor(currentChillTime / TimeUnits.Days).toString()
      );
    } else if (currentChillTime >= TimeUnits.Hours) {
      setTimeUnit(TimeUnits.Hours);
      setChillTimeDisplay(
        Math.floor(currentChillTime / TimeUnits.Hours).toString()
      );
    } else {
      setTimeUnit(TimeUnits.Minutes);
      setChillTimeDisplay(
        Math.floor(currentChillTime / TimeUnits.Minutes).toString()
      );
    }
  }, [msCallOptions.recurrency?.chillTime]);

  useEffect(() => {
    if (isOptions) {
      update({
        recurrency: { chillTime: '60', maxRepeats: '1000' },
      });
    }
  }, [isOptions, update]);

  return (
    <HStack
      padding="30px"
      border="1px"
      bg="blackAlpha.50"
      borderColor="blackAlpha.50"
      _dark={{ borderColor: 'whiteAlpha.50', bg: 'whiteAlpha.50' }}
      rounded="3xl"
      w="1050px"
      spacing="50px"
      align="flex-start"
      justify="space-between"
    >
      <Stack flex="1" spacing="32px" {...props}>
        <Stack>
          <HStack spacing="0" gap="10px">
            <Text fontWeight="semibold">{t('name')}</Text>
            <InfoPopover description={t('name-your-intent')} />
          </HStack>
          <Input
            value={msCallOptions.name}
            placeholder={name}
            onInput={(e) => update({ name: e.currentTarget.value })}
            variant="filled"
            // bg="whiteAlpha.100"
            rounded="18px"
            h="55px"
          />
          {errors.name && (
            <Text color="orange.500" fontSize="sm">
              {errors.name.message}
            </Text>
          )}
        </Stack>
        <Stack>
          <Stack>
            <HStack spacing="0" gap="10px">
              <Text fontWeight="semibold">{t('valid-from')}</Text>
              <InfoPopover
                description={t('intents-possible-execution-starting-time')}
              />
            </HStack>
            <Stack flex="1">
              <Input
                type="datetime-local"
                variant="filled"
                rounded="18px"
                h="55px"
                value={secondsToDate(Number(msCallOptions.validFrom))}
                onInput={(e) => {
                  const validFrom = moment(e.currentTarget.value).unix();
                  if (!validFrom) return;
                  if (isNaN(validFrom)) return;
                  update({
                    validFrom,
                  });
                }}
              />
              {errors.validFrom && (
                <Text color="orange.500" fontSize="sm">
                  {errors.validFrom.message}
                </Text>
              )}
            </Stack>
            <HStack spacing="0" gap="10px">
              <HStack>
                <Text fontWeight="semibold">{t('expires-at')}</Text>
              </HStack>
              <InfoPopover
                description={moment
                  .unix(Number(msCallOptions.expiresAt))
                  .from(moment.unix(Number(msCallOptions.validFrom)), true)}
              />
            </HStack>
            <Stack flex="1">
              <Stack
                spacing="3px"
                bg="gray.100"
                _dark={{ bg: 'whiteAlpha.50' }}
                rounded="18px"
              >
                <Input
                  ref={expiresAtElement}
                  type="datetime-local"
                  variant="filled"
                  bg="none"
                  _hover={{
                    bg: 'gray.200',
                    _dark: { bg: 'whiteAlpha.100' },
                  }}
                  _focus={{
                    bg: 'none',
                    _dark: { bg: 'none' },
                  }}
                  rounded="18px"
                  h="55px"
                  value={secondsToDate(Number(msCallOptions.expiresAt))}
                  onInput={(e) => {
                    const expiresAt = moment(e.currentTarget.value).unix();
                    if (!expiresAt) return;
                    if (isNaN(expiresAt)) return;
                    update({
                      expiresAt,
                    });
                  }}
                />
                <HStack p="2px" spacing="2px" h="35px" rounded="18px">
                  {Object.keys(expiresAtOptions).map((key) => (
                    <Button
                      key={expiresAtOptions[key].label}
                      border={`2px solid ${expiresAtDisplay.label === expiresAtOptions[key].label ? '#63b3ed' : 'transparent'}`}
                      _hover={{
                        borderColor: '#4299e1',
                        bg: 'gray.200',
                        _dark: { bg: 'whiteAlpha.100' },
                      }}
                      rounded="18px"
                      variant="ghost"
                      w="full"
                      h="full"
                      onClick={() => {
                        if (key === 'custom') {
                          expiresAtElement.current?.focus();
                          return;
                        }

                        const expiresAt = moment
                          .unix(Number(msCallOptions.validFrom))
                          .add(Number(expiresAtOptions[key].value), 'seconds')
                          .unix();

                        update({
                          expiresAt,
                        });
                      }}
                    >
                      {expiresAtOptions[key].label}
                    </Button>
                  ))}
                </HStack>
              </Stack>
              {errors.expiresAt && (
                <Text color="orange.500" fontSize="sm">
                  {errors.expiresAt.message}
                </Text>
              )}
            </Stack>
          </Stack>
        </Stack>
        <Box>
          <Stack mt="-30px">
            <FormControl
              cursor="pointer"
              mt="30px"
              display="flex"
              alignItems="center"
            >
              <HStack justifyContent="space-between">
                <FormLabel flex="1" htmlFor="virtualPause" mb="0">
                  <HStack spacing="0" gap="10px">
                    <Text fontWeight="semibold">{t('virtual-pause')}</Text>
                    <InfoPopover description={t('virtual-pause-gas-free')} />
                  </HStack>
                </FormLabel>
                <Switch
                  isChecked={isVirtualPauseEnabled}
                  onChange={toggleVirtualPause}
                  id="virtualPause"
                />
              </HStack>
            </FormControl>
          </Stack>
        </Box>
        <Box>
          {isOptions && (
            <Text color="blue.500" fontSize="sm">
              {t('recurrency-cannot-be-edited-for-apy-based-intents')}
            </Text>
          )}
          {!isOptions && (
            <>
              <Stack mt="-30px">
                <FormControl
                  cursor="pointer"
                  mt="30px"
                  display="flex"
                  alignItems="center"
                >
                  <HStack justifyContent="space-between">
                    <FormLabel flex="1" htmlFor="recurrency" mb="0">
                      <HStack spacing="0" gap="10px">
                        <Text fontWeight="semibold">{t('recurrency')}</Text>
                        <InfoPopover
                          description={t('publish-intent-more-than-one-time')}
                        />
                      </HStack>
                    </FormLabel>
                    <Switch
                      isChecked={isRecurrencyEnabled}
                      onChange={toggleRecurrency}
                      id="recurrency"
                    />
                  </HStack>
                </FormControl>
              </Stack>
              <Collapse in={isRecurrencyEnabled}>
                <HStack spacing="16px" py="6">
                  <Stack>
                    <HStack spacing="0" gap="10px">
                      <Text fontWeight="semibold">{t('repeat')}</Text>
                      <InfoPopover
                        description={t(
                          'how-many-times-should-the-intent-publish'
                        )}
                      />
                    </HStack>
                    <InputGroup>
                      <Input
                        type="number"
                        disabled={maxCountEnabled}
                        value={msCallOptions.recurrency?.maxRepeats}
                        onInput={(e) => {
                          const userInput = Math.abs(
                            Math.ceil(Number(e.currentTarget.value))
                          );
                          const calculatedMax = Math.abs(
                            Number(calcMaxRepeats())
                          );
                          const allowedRepeats = Math.min(
                            calculatedMax,
                            userInput
                          ).toString();

                          if (calculatedMax < userInput) {
                            setMaxCountEnabled(true);
                          }

                          update({
                            recurrency: {
                              maxRepeats: allowedRepeats,
                            },
                          });
                        }}
                        variant="filled"
                        // bg="whiteAlpha.100"
                        rounded="18px"
                        h="55px"
                      />
                      <InputRightElement h="full" pr="4" w="fit-content">
                        <Tag
                          ml="auto"
                          rounded="full"
                          onClick={() => {
                            setMaxCountEnabled((prev) => !prev);
                          }}
                          cursor="pointer"
                          colorScheme={maxCountEnabled ? 'primary' : 'gray'}
                          variant={maxCountEnabled ? 'subtle' : 'outline'}
                          opacity={maxCountEnabled ? 1 : 0.5}
                        >
                          {t('max')}{' '}
                        </Tag>
                      </InputRightElement>
                    </InputGroup>
                    {errors.recurrency?.maxRepeats && (
                      <Text color="orange.500" fontSize="sm">
                        {errors.recurrency?.maxRepeats?.message}
                      </Text>
                    )}
                  </Stack>
                  <Stack>
                    <HStack spacing="0" gap="10px">
                      <Text fontWeight="semibold">{t('chill-time')}</Text>
                      <InfoPopover description={t('interval-period')} />
                    </HStack>
                    <InputGroup>
                      <Input
                        type="number"
                        value={chillTimeDisplay}
                        onInput={(e) => {
                          updateChillTimeDisplay(e.currentTarget.value);
                        }}
                        variant="filled"
                        rounded="18px"
                        h="55px"
                      />
                      <InputRightElement h="full" pr="4" w="fit-content">
                        <Menu placement="top" strategy="fixed">
                          <MenuButton
                            ml="auto"
                            as={Tag}
                            rounded="full"
                            cursor="pointer"
                            colorScheme="primary"
                          >
                            <HStack>
                              <Text>{HUMAN_TIME_UNITS[timeUnit]}</Text>
                              <Icon as={ChevronDown} />
                            </HStack>
                          </MenuButton>
                          <MenuList zIndex={99}>
                            <MenuItem
                              onClick={() => updateTimeUnit(TimeUnits.Days)}
                            >
                              {t('days')}{' '}
                            </MenuItem>
                            <MenuItem
                              onClick={() => updateTimeUnit(TimeUnits.Hours)}
                            >
                              {t('hours')}{' '}
                            </MenuItem>
                            <MenuItem
                              onClick={() => updateTimeUnit(TimeUnits.Minutes)}
                            >
                              {t('minutes')}{' '}
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </InputRightElement>
                    </InputGroup>
                    {errors.recurrency?.chillTime && (
                      <Text color="orange.500" fontSize="sm">
                        {errors.recurrency?.chillTime?.message}
                      </Text>
                    )}
                  </Stack>
                </HStack>
              </Collapse>
            </>
          )}
        </Box>
      </Stack>
      <Stack flex="1" spacing="32px" {...props}>
        <GasDetailsAdvanced
          isMagicExist={isMagicExist}
          gasOption={gasOption}
          setGasOption={setGasOption}
          converted={converted}
          immediateOrSoonGwei={immediateOrSoonGwei}
          flexibleTimingGwei={flexibleTimingGwei}
          immediateOrSoonWei={immediateOrSoonWei}
          flexibleTimingWei={flexibleTimingWei}
        />
      </Stack>
    </HStack>
  );
};
