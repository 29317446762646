import type { BadgeProps } from '@chakra-ui/react';
import { Badge } from '@chakra-ui/react';
import type { FC } from 'react';
import { useNetwork } from '../hooks/useNetwork';

export const NetworkNameBadge: FC<BadgeProps> = (props) => {
  const { caipNetwork } = useNetwork();
  const bg: BadgeProps['colorScheme'] = caipNetwork?.testnet ? 'orange' : 'green';

  return (
    <Badge variant="subtle" colorScheme={bg} px="2" {...props} rounded="full">
      {caipNetwork?.name}
    </Badge>
  );
};
