import { HStack, Icon, Text, Tooltip } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { forwardRef } from 'react';
import { LuChevronDown, LuChevronUp, LuChevronsUpDown } from 'react-icons/lu';

import type { TextProps } from '@chakra-ui/react';
import type { AppsDefiDataOrderByType, OrderDirType } from '@kiroboio/fct-sdk';
import type { LegacyRef } from 'react';
import { InfoPopover } from '../builder/components/FCTSettings';

interface ITableTitle extends TextProps {
  title: string;
  description?: string;
  orderByTitle?: AppsDefiDataOrderByType;
  orderDir?: OrderDirType;
  orderBy?: AppsDefiDataOrderByType;
  onOrderChange?: (nextOrderBy: AppsDefiDataOrderByType) => void;
}

export const TradingTableTitle = forwardRef(
  (
    {
      title,
      description,
      orderByTitle,
      orderBy,
      orderDir,
      onOrderChange,
      ...rest
    }: ITableTitle,
    ref: LegacyRef<HTMLDivElement> | undefined
  ) => {
    const t = useTranslations();

    const renderSortIcon = () => {
      if (!orderBy) return null;
      if (orderBy !== orderByTitle) return <Icon as={LuChevronsUpDown} />;
      if (orderBy === orderByTitle)
        return <Icon as={orderDir === 'asc' ? LuChevronUp : LuChevronDown} />;
      return null;
    };

    return (
      <HStack
        onClick={(e) => {
          e.stopPropagation();
          if (orderByTitle) onOrderChange?.(orderByTitle);
        }}
        color="gray.500"
      >
        <HStack spacing="3px">
          <Text fontWeight="semibold" {...rest}>
            {title}
          </Text>
          {description && (
            <InfoPopover boxSize="16px" description={description} />
          )}
        </HStack>
        {orderBy && (
          <Tooltip
            label={`${t('sort-by')} ${title}`}
            aria-label={`${t('sort-by')} ${title}`}
          >
            <HStack ref={ref}>{renderSortIcon()}</HStack>
          </Tooltip>
        )}
      </HStack>
    );
  }
);

// export const TableTitle: FC<ITableTitle> = (props) => {
//   const t = useTranslations();

//   if (!props.orderBy) return <Title {...props} />;
//   return (
//     <Tooltip
//       label={`${t('sort-by')} ${props.title}`}
//       aria-label={`${t('sort-by')} ${props.title}`}
//     >
//       <Title {...props} cursor="pointer" />
//     </Tooltip>
//   );
// };
