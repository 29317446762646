import type { BoxProps } from '@chakra-ui/react';
import { Icon, useClipboard, Box, Tooltip } from '@chakra-ui/react';
import { CheckCircle } from 'react-feather';
import { Copy } from 'iconsax-react';
import reactToText from 'react-to-text';
import type { ElementType, ReactNode } from 'react';

interface IProps extends BoxProps {
  CustomIcon?: ElementType;
  size?: string;
  value?: ReactNode | string;
  tooltip?: React.ReactNode;
  hasCopied?: boolean;
}
export const CopyButton = ({
  value,
  size,
  tooltip,
  hasCopied,
  CustomIcon,
  ...props
}: IProps) => {
  const nodeStringValue = reactToText(value);
  const { onCopy, hasCopied: isValueCopied } = useClipboard(
    nodeStringValue || ''
  );
  const isCopied = isValueCopied || hasCopied;
  return (
    <Box
      cursor="pointer"
      display="flex"
      alignItems="flex-end"
      justifyContent="flex-end"
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
      }}
      onClick={onCopy}
      {...props}
    >
      <Tooltip openDelay={300} label={tooltip || value}>
        <Icon
          as={isCopied ? CheckCircle : CustomIcon || Copy}
          color={isCopied ? 'green.500' : 'inherit'}
          boxSize={size || '16px'}
        />
      </Tooltip>
    </Box>
  );
};
