'use client';

import type { StackProps } from '@chakra-ui/react';
import { Avatar, Flex, Text, Tooltip } from '@chakra-ui/react';
import type { FC } from 'react';

import { useDappConnectSessions } from '../hooks/useDappConnect/useDappConnect';

export const ConnectedDappIcons: FC<StackProps> = (props) => {
  const { sessions: sessionsMap } = useDappConnectSessions();

  const sessions = Object.values(sessionsMap);
  const first3Sessions = sessions.slice(0, 3);
  const remainingSessions = sessions.length - first3Sessions.length;

  if (!sessions.length) return null;

  return (
    <Tooltip label="Connected dapps to your Runner" hasArrow placement="top">
      <Flex align="center" {...props}>
        {first3Sessions.map((session, i) => (
          <Avatar
            key={session.topic}
            name={session.peer.metadata.name}
            src={session.peer.metadata.icons[0]}
            size="2xs"
            bg="gray.600"
            border="1px solid var(--chakra-colors-green-300)"
            marginLeft={i > 0 ? '-3px' : undefined}
            shadow="lg"
          />
        ))}
        {remainingSessions > 0 && (
          <Text ml="3px" fontSize="xs" fontWeight="bold" color="gray.500">
            +{remainingSessions}
          </Text>
        )}
      </Flex>
    </Tooltip>
  );
};
